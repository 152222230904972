import React from 'react'
import { Link } from 'gatsby'
import { Container, Navbar, Nav } from 'react-bootstrap'
import SlashBackground from './SlashBackground'

const Navigation = () => {
    return (
        <div 
            className="container-fluid"
            style={{ backgroundColor: '#348841' }}
        >
            <Container fluid>
                <Navbar expand="lg" id="site-navbar" variant="dark" className="navbar-fixed-top mr-sm-2">
                    <Navbar.Brand>
                        <div className="logo-container">
                        <Link to="/">
                            <SlashBackground />
                        </Link>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="justify-content-end mr-sm-2" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="justify-content-end" style={{ flexGrow: '1' }}>
                            <Link className="navbar-link" to="/" activeClassName="active">
                                <Nav.Link as="span">Home</Nav.Link>
                            </Link>
                            <Link className="navbar-link" to="technology" activeClassName="active">
                                <Nav.Link as="span">Technology</Nav.Link>
                            </Link>
                            <Link className="navbar-link" to="about" activeClassName="active">
                                <Nav.Link as="span">About Us</Nav.Link>
                            </Link>
                            <Link className="navbar-link" to="news-events" activeClassName="active">
                                <Nav.Link as="span">News &amp; Events</Nav.Link>
                            </Link>
                            <Link className="navbar-link" to="contact" activeClassName="active">
                                <Nav.Link as="span">Contact Us</Nav.Link>
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </div>
    )
}

export default Navigation