import React from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'

const FooterForm = () => {
    return (
        <>
            <Form>
                <Row>
                    <Col xs={12} md={6} style={{
                    padding: '0px'
                }}>
                        <Form.Control type="email" placeholder="Enter email" />
                    </Col>
                    <Col xs={12} md={6} className="subscribe-col">
                        <Button className="btn btn-subscribe" type="submit">
                            Subscribe
                        </Button>
                    
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default FooterForm