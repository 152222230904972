import React from 'react'

const SlashBackground = ({
    style = {},
    fill = '#E7E6E6',
    width = 'auto',
    className = '',
    height = 'auto',
    viewBox = '0 0 400 130'
}) => {
    return (
        <>
    <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <polygon className="cls-1" style={{fill: '#e7e6e6'}} points="269.981 130.019 0 130.019 0 0 400 0 269.981 130.019"/>
      <g>
        <path className="cls-2" style={{fill: '#475b70'}} d="M235.87167,48.91988l-4.2849,2.87339A7.18178,7.18178,0,0,0,221.24932,58.928v.047l.02351.14114c1.13008,8.64241,12.85637,7.8888,14.17543.2831a7.17986,7.17986,0,0,0-1.88369-6.17017l.75362-.494h0l18.71993-12.48073a7.471,7.471,0,0,1,.047-4.3085,7.3369,7.3369,0,0,1,1.4367-2.63729C251.69555,29.56438,248.87,25.8441,246.021,22.0999a1.29229,1.29229,0,0,0,.42432-.02351,3.00262,3.00262,0,0,0-.7537-5.95768,3.01733,3.01733,0,0,0-2.61369,3.3674,2.95165,2.95165,0,0,0,1.177,2.02524l2.94316,3.88534c-.09411.02351-.21173.0471-.32937.07061a6.4095,6.4095,0,1,1,5.53344-7.1587A6.323,6.323,0,0,1,251.225,22.877c2.96667,3.90893,5.95759,7.84137,8.92426,11.7498a3.56978,3.56978,0,1,0,1.5073.23567l-3.08512-4.07358A7.61438,7.61438,0,1,1,258.101,45.4592a7.568,7.568,0,0,1-2.73142-1.5073L239.6869,54.40706h0a11.81694,11.81694,0,0,1-.047,7.70015,11.94015,11.94015,0,0,1-7.67664,7.46448h0a11.94,11.94,0,0,1-11.82041-2.75493,11.68555,11.68555,0,0,1-3.13214-5.03976h0a11.92309,11.92309,0,0,1,18.861-12.85712Z"/>
        <path className="cls-3" style={{fill: '#348841'}} d="M227.58339,84.87712c8.94785,0,16.34223-3.22543,21.1925-9.15952l-7.6052-7.01715A16.54721,16.54721,0,1,1,239.80453,46.212l8.68909-5.698c-4.85119-5.65148-12.15061-8.7597-20.83962-8.7597-15.98852,0-27.88029,11.09114-27.88029,26.5613,0,15.49483,11.89177,26.56155,27.80968,26.56155Z"/>
        <polygon className="cls-3" style={{fill: '#348841'}} points="177.522 32.649 177.522 63.826 151.973 32.649 142.154 32.649 142.154 84.006 153.881 84.006 153.881 52.806 179.499 84.006 189.249 84.006 189.249 32.649 177.522 32.649"/>
        <polygon className="cls-3" style={{fill: '#348841'}} points="136.102 32.649 124.447 32.649 110.718 55.467 97.014 32.649 84.393 32.649 104.267 65.663 104.267 84.006 116.158 84.006 116.158 65.804 136.102 32.649"/>
        <path className="cls-3" style={{fill: '#348841'}}  d="M61.10439,84.87712c14.66987,0,21.80465-7.34644,21.80465-15.91791,0-18.83789-29.8816-12.29208-29.8816-21.78188,0-3.24894,2.73141-5.88624,9.772-5.88624A28.80145,28.80145,0,0,1,77.0223,45.34074l3.67326-9.042c-4.75666-2.99017-11.37341-4.54449-17.82511-4.54449-14.59967,0-21.64033,7.27591-21.64033,16.012,0,19.07406,29.85809,12.48,29.85809,22.08775,0,3.13215-2.87255,5.48717-9.91321,5.48717a30.26943,30.26943,0,0,1-16.95429-5.346l-4.02655,8.94869c4.54449,3.50853,12.76267,5.93325,20.91023,5.93325Z"/>
        <path className="cls-2" style={{fill: '#475b70'}} d="M51.7323,103.22067a3.29734,3.29734,0,0,0,1.83658-3.03728c0-2.1432-1.766-3.57915-5.20357-3.57915H41.63044V110.379h7.11168c3.62616,0,5.50985-1.38884,5.50985-3.76738a3.40394,3.40394,0,0,0-2.51967-3.3909Zm-3.76738-4.21513c1.5539,0,2.40163.54187,2.40163,1.62485,0,1.08381-.84773,1.62492-2.40163,1.62492H44.78575V99.00554Zm.54153,8.97212h-3.7207v-3.39091h3.7207c1.64843,0,2.54318.54112,2.54318,1.69545,0,1.1771-.89475,1.69546-2.54318,1.69546ZM77.0223,110.379h3.17883V96.60424H77.0223Zm33.46074.236c4.30924,0,7.51158-3.01451,7.51158-7.11118,0-4.121-3.20234-7.13553-7.51158-7.13553-4.35626,0-7.53509,3.038-7.53509,7.13553,0,4.07316,3.17883,7.11118,7.53509,7.11118Zm0-2.70782a4.40439,4.40439,0,1,1,4.30924-4.40336,4.19271,4.19271,0,0,1-4.30924,4.40336Zm39.74771-11.30289v8.3589l-6.85159-8.3589H140.741V110.379h3.15565v-8.35889l6.85168,8.35889h2.61379V96.60424ZM176.98062,110.379H180.159V96.60424h-3.17842Zm33.3893.236a7.12233,7.12233,0,0,0,5.67542-2.44906l-2.04843-1.9072a4.373,4.373,0,0,1-3.46151,1.64844,4.40336,4.40336,0,0,1,0-8.80672,4.39961,4.39961,0,0,1,3.46151,1.60059l2.04843-1.88369a7.12233,7.12233,0,0,0-5.67542-2.44907c-4.2849,0-7.46406,2.96742-7.46406,7.13553,0,4.14377,3.17916,7.11118,7.46406,7.11118Zm32.566,0c3.93277,0,5.84-1.97855,5.84-4.26214,0-5.06285-8.006-3.29687-8.006-5.84005,0-.87124.7301-1.57783,2.61379-1.57783a7.7158,7.7158,0,0,1,3.81439,1.08381l.9897-2.42555a9.31132,9.31132,0,0,0-4.78058-1.225c-3.90843,0-5.793,1.95505-5.793,4.30925,0,5.10987,7.98333,3.3438,7.98333,5.91058,0,.84772-.75369,1.4602-2.6373,1.4602a8.2534,8.2534,0,0,1-4.54533-1.41319l-1.083,2.37854a9.82359,9.82359,0,0,0,5.604,1.60133Z"/>
      </g>
    </g>
  </g>
</svg>
        </>
    )

}

export default SlashBackground