import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
import FooterForm from "./FooterForm"

import FooterWedge from "../images/footer-wedge.svg"
import FooterLogo from "../images/sync-bionics-footer-logo.svg"

const Footer = () => (
  <footer>
    <Container fluid>
      <Row>
        <Col md={2} className="d-flex justify-content-left align-items-center footer-items-container">
          
          <ul className="footer-nav-list">
            <li>
              <Link to="/" className="footer-nav">
                Home
              </Link>
            </li>
            <li>
              <Link to="/technology" className="footer-nav">
                Technology
              </Link>
            </li>
            <li>
              <Link to="/about" className="footer-nav">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/news-events" className="footer-nav">
                News &amp; Events
              </Link>
            </li>
            <li>
              <Link to="/contact" className="footer-nav">
                Contact Us
              </Link>
            </li>
          </ul>
          
        
        </Col>
        <Col md={4} className="d-flex justify-content-left align-items-center footer-items-container about-us"
            style={{
                flexFlow: 'wrap',
            }}
        >
            <div>
        <h4 style={{
            color: 'white',
            textTransform: 'uppercase',
            fontSize: '1rem',
            fontWeight: 'bold'
        }}>
            About Us
        </h4>
            <p className="footer-text">
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
            </p>
            <p className="footer-text">Sync Bionics technology is investigational and not approved by the FDA</p>
            </div>
        </Col>
        <Col md={3} className="d-flex justify-content-left align-items-center footer-items-container">
        <div style={{
            width: '100%'
        }}>
        <h4 className="stay-in-touch" style={{
            color: 'white',
            textTransform: 'uppercase',
            fontSize: '1rem',
            fontWeight: 'bold'
        }}>
            Stay in touch
        </h4>
            <FooterForm />
        </div>
        </Col>
        <Col md={3} className="footer-logo-slash">
          <div className="footer-logo-container">
            <div className="footer-logo-block"
              // style={{
              //   position: "absolute",
              //   alignSelf: 'center',
              // }}
            >
              <img
                src={FooterLogo}
                style={{
                  width: "120px",
                  borderBottom: "1px solid #707070",
                  paddingBottom: "10px",
                }}
              />
              <div style={{}}>
                <p
                  style={{
                    fontSize: "0.8rem",
                  }}
                >
                  Sync bionics
                  <br />
                  2056 S 1100 E<br />
                  Salt Lake City, UT 84106
                </p>
              </div>
            </div>
            <div className="footer-wedge">
            <img 
              src={FooterWedge}
              style={{
                width: "100%",
                fill: "#e7e6e6",
              }}
            />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
